<template>
  <!-- ACCOUNT -->
  <v-card elevation="0" color="transparent">
    <v-toolbar
      elevation="0"
      color="transparent"
      :class="['qr-toolbar--account', $store.state.layout.snackbar.active ? 'qr-toolbar--account--has-alert' : null]"
      height="auto"
    >
      <!-- TITLE CONTAINER -->
      <v-container>
        <v-row justify="center">
          <v-col lg="10" xl="8">
            <!-- Title -->
            <v-toolbar-title>
              <h2 class="text-h2">
                {{ activeTabTitle }}
              </h2>
            </v-toolbar-title>
          </v-col>
        </v-row>
      </v-container>
      <!-- TITLE CONTAINER END -->

      <!-- TABS -->
      <template #extension>
        <v-container class="py-0">
          <v-row justify="center">
            <v-col lg="10" xl="8" class="py-0">
              <v-tabs
                v-model="activeTab"
                color="navy base"
                class="qr-tabs qr-tabs-bar--transparent qr-tabs-bar--items-space mt-4"
                next-icon="$chevron-system-small-outline"
                prev-icon="$chevron-system-small-outline"
                show-arrows
              >
                <!-- Slider -->
                <v-tabs-slider color="primary base" />

                <!-- Tabs -->
                <v-tab v-for="tab in tabs" :key="tab.id" :ripple="false" :to="tab.route" exact>
                  {{ tab.title }}
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <!-- TABS END -->
    </v-toolbar>

    <!-- TABS ITEMS -->
    <v-tabs-items v-model="activeTab" class="qr-tabs-items--transparent">
      <v-tab-item key="account" />
      <v-tab-item key="user_management"> User Management </v-tab-item>
      <v-tab-item key="white_label"> White label </v-tab-item>
      <v-tab-item key="api_keys"> API keys </v-tab-item>
      <v-tab-item key="billing" value="/my-account/billing">
        <v-container class="qr-container--account">
          <v-row justify="center">
            <v-col lg="10" xl="8" class="qr-col--account-main">
              <BillingDetail />
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
    <!-- TABS ITEMS END -->
  </v-card>
  <!-- ACCOUNT END -->
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import { i18n } from '@/i18n';
import BillingDetail from '@/Pages/AccountPage/BillingDetail/BillingDetail.vue';
import { User } from '@/classes/auth';

@Component({
  components: { BillingDetail },
})
export default class Account extends Vue {
  user: User = new User();
  activeTab?: any = '/my-account';
  tabs: Array<{ id: number; key: string; title: string; route: string }> = [
    {
      id: 1,
      key: 'account',
      title: i18n.t('fields.account.tabs.account_tab').toString(),
      route: '/my-account',
    },
    {
      id: 2,
      key: 'user_management',
      title: i18n.t('fields.account.tabs.user_management_tab').toString(),
      route: '/my-account/user-management',
    },
    {
      id: 3,
      key: 'white_label',
      title: i18n.t('fields.account.tabs.white_label_tab').toString(),
      route: '/my-account/white-label',
    },
    {
      id: 4,
      key: 'api_keys',
      title: i18n.t('fields.account.tabs.api_keys_tab').toString(),
      route: '/my-account/api-keys',
    },
    {
      id: 5,
      key: 'billing',
      title: i18n.t('fields.account.tabs.billing_tab').toString(),
      route: '/my-account/billing',
    },
  ];

  created(): void {
    this.activeTab = this.$route.path;
  }

  get activeTabTitle(): string {
    try {
      if (this.tabs.length) {
        return this.tabs.find((element) => element.route === this.activeTab)!.title;
      }
      return '';
    } catch {
      return 'n/a';
    }
  }
}
</script>
