<template>
  <v-container v-if="!isUnsubscribingError" class="mt-9">
    <!-- HEADER -->
    <v-row class="justify-center">
      <v-col cols="12" sm="12" md="12" lg="8" xl="5">
        <div class="d-flex justify-space-between align-center">
          <h2 class="text-h2">
            {{ $t('fields.account.billing.cancel_subs.page_title') }}
          </h2>

          <v-btn text :ripple="false" color="primary" to="my-account/billing">
            <Icon name="arrow-system" outline left class="icon--mirror" />
            <span class="navy--text">
              {{ $t('fields.account.billing.cancel_subs.page_back') }}
            </span>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <!-- HEADER END -->

    <!-- CONTENT -->
    <v-row class="justify-center">
      <v-col cols="12" sm="12" md="12" lg="8" xl="5">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card text flat class="py-6">
            <v-card-text class="py-0 px-10">
              <h5 class="text-h5 lapis--text text--lighten-2 mb-2">
                {{ $t('fields.account.billing.cancel_subs.page_checking') }}
                <v-progress-circular
                  v-if="!subscription.current_period_end"
                  indeterminate
                  :width="2"
                  size="14"
                  color="lapis lighten-2"
                />
                <template v-else>
                  {{ currentPeriodEnd }} {{ $t('fields.account.billing.cancel_subs.page_checking_required_label') }}:
                </template>
              </h5>
              <div data-test="cancellation-approval-checkboxes" class="d-flex flex-column">
                <div v-for="answer in answerFour" :key="answer" class="d-flex align-center mt-2">
                  <Icon name="forbidden-system" outline class="begonia--text mr-2" />
                  <span class="text-h5 font-weight-semi-bold lapis--text">{{ answer }}</span>
                </div>

                <QrCheckbox
                  key="acknowledge"
                  v-model="isAcknowledged"
                  input-id="acknowledge"
                  color="navy"
                  class="mt-4"
                  border
                >
                  <template #label>
                    <span v-html="$t('fields.account.billing.cancel_subs.page_check_acknowledge')" />
                  </template>
                </QrCheckbox>

                <error-message
                  :invalid="submitted && checkboxesInvalid"
                  data-test="cancellation-approval-checkboxes-error"
                >
                  {{ $t('fields.account.billing.cancel_subs.page_all_checkboxes_error') }}
                </error-message>
              </div>

              <h5 class="text-h5 lapis--text text--lighten-1 mb-0 mt-4">
                {{ $t('fields.account.billing.cancel_subs.page_reason') }}
              </h5>

              <div>
                <v-radio-group v-model="selectedCancellationOption" class="radio-selection fix-gap">
                  <template v-for="option in cancellationReasons">
                    <v-radio
                      :key="option.id"
                      :label="option.label"
                      :value="option.value"
                      color="navy"
                      class="radio-selection__type"
                      :data-test="`cancellation-reason-${option.value}`"
                    />
                    <div
                      v-if="option.value === selectedCancellationOption && option.value !== 'other'"
                      :key="`element--${option.id}`"
                    >
                      <counter-argument-banner
                        :background-color="option.counterArgumentBanner.backgroundColor"
                        :title="option.counterArgumentBanner.title"
                        :description="option.counterArgumentBanner.description"
                      >
                        <template #icon-left>
                          <v-img
                            :src="
                              require('../../assets/images/cancellation-form/' +
                                option.counterArgumentBanner.iconName +
                                '.svg')
                            "
                            :alt="option.counterArgumentBanner.title"
                            :max-width="64"
                            :max-height="64"
                          />
                        </template>

                        <template v-if="option.counterArgumentBanner.actions" #actions>
                          <v-btn
                            v-for="action in option.counterArgumentBanner.actions"
                            :key="action.id"
                            rounded
                            :color="action.buttonColor"
                            :outlined="action.buttonOutlined"
                            :ripple="false"
                            small
                            depressed
                            :href="action.buttonLink"
                            target="_blank"
                          >
                            {{ action.buttonCta }}
                          </v-btn>
                        </template>
                      </counter-argument-banner>
                    </div>
                  </template>
                </v-radio-group>

                <v-textarea
                  v-model="cancellationOtherReason"
                  outlined
                  counter="250"
                  maxlength="250"
                  :placeholder="$t('fields.account.billing.cancel_subs.page_option_other_placeholder')"
                />
                <error-message :invalid="submitted && !selectedCancellationOption">
                  {{ $t('fields.account.billing.cancel_subs.page_all_reasons_error') }}
                </error-message>
              </div>
            </v-card-text>

            <v-divider class="divider--large divider--cancel-subscription mt-4 mb-8" />

            <v-card-actions class="py-0 px-10 justify-space-between align-center">
              <v-btn
                rounded
                color="begonia"
                depressed
                :ripple="false"
                large
                outlined
                :disabled="isUnsubscribing"
                :loading="isUnsubscribing"
                data-test="cancellation-submit-btn"
                @click="onClickCancelSubscription"
              >
                {{ $t('fields.account.billing.cancel_subs.page_btn_outline') }}
              </v-btn>
              <v-btn rounded color="primary" depressed :ripple="false" large @click="onKeepSubscription">
                {{ $t('fields.account.billing.cancel_subs.page_btn_primary') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>

    <discount-offer-modal
      v-if="isDiscountOfferModalShown"
      :discount-info="discountInfo"
      :discount-info-loading="discountInfoLoading"
      @closeModal="isDiscountOfferModalShown = false"
      @declineOffer="cancelSubscription(true)"
    />

    <!-- CONTENT END -->
  </v-container>

  <v-container v-else class="mt-9">
    <v-row class="justify-center">
      <v-col cols="9">
        <EmptyState illustration="error_illustration.png" :width="352">
          <h2 class="text-h2">
            {{ $t('fields.account.billing.cancel_subs.page_subs_error_title') }}
          </h2>
          <p
            class="body-1 lapis--text text--lighten-2 mt-3"
            v-html="$t('fields.account.billing.cancel_subs.page_subs_error_desc')"
          />

          <!-- Create button -->
          <v-btn depressed rounded :ripple="false" color="primary" x-large href="/my-account/billing" class="mt-10">
            <Icon name="arrow-system" outline large left class="icon--mirror" />
            {{ $t('fields.account.billing.payment.try_again') }}
          </v-btn>
        </EmptyState>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import FeedbackModel from '@/Pages/CancelSubscriptionPage/FeedbackModel';
import {
  trackCancelSubscription,
  trackCancelSubscriptionKeepSubscriptionButtonClicked,
} from '@/Services/Snowplow/SnowplowService';
import { SnowplowCancellationReasonIdentifier } from '@/Services/Snowplow/models';
import StripeSubscriptionService from '@/Services/Subscription/StripeSubscriptionService';
import EmptyState from '@/components/EmptyState.vue';
import Icon from '@/components/Icons/Icon.vue';
import QrCheckbox from '@/components/Input/QrCheckbox.vue';
import store from '@/store';
import type { Route } from '@sentry/vue/types/router';
import dayjs from 'dayjs';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { NavigationGuardNext } from 'vue-router';
import CounterArgumentBanner from '@/components/CancellationForm/CounterArgumentBanner.vue';
import { TranslateResult } from 'vue-i18n';
import DiscountOfferModal from '@/components/CancellationForm/DiscountOfferModal.vue';
import { optimizelyFeatureFlags } from '@/plugins/optimizely-feature-flags';
import { isFeatureEnabled } from '@/Services/FeatureFlagService';
import PromoCodeService, { DiscountInfo } from '@/Services/PromoCodeService';

interface CancellationReasonsInterface {
  id: number;
  label: TranslateResult;
  value: string;
  counterArgumentBanner?: {
    backgroundColor: string;
    title: string;
    description?: string;
    iconName: string;
    actions?: Array<{
      id: number;
      buttonColor: 'primary' | 'banana darken-1';
      buttonOutlined?: boolean;
      buttonCta: string;
      buttonLink: string;
    }>;
  };
}

@Component({
  components: {
    CounterArgumentBanner,
    Icon,
    EmptyState,
    QrCheckbox,
    DiscountOfferModal,
  },
})
export default class CancelSubscription extends Vue {
  // DATA
  form: FeedbackModel = new FeedbackModel();
  isAcknowledged: boolean = false;
  answerFour: Array<TranslateResult> = [
    this.$t('fields.account.billing.cancel_subs.page_check_one'),
    this.$t('fields.account.billing.cancel_subs.page_check_two'),
    this.$t('fields.account.billing.cancel_subs.page_check_three'),
    this.$t('fields.account.billing.cancel_subs.page_check_four'),
    this.$t('fields.account.billing.cancel_subs.page_check_five'),
  ];

  subscription = store.state.subscription.activeSubscription;
  stripeSubscriptionService: StripeSubscriptionService = new StripeSubscriptionService();

  // Loading states
  isUnsubscribing = false;
  isUnsubscribingError = false;

  isDiscountOfferModalShown = false;

  // DATA END

  // FORM VALIDATION
  valid = true;
  submitted = false;

  @Watch('selectedCancellationOption')
  async onSelectedCancellationOption(_: string): Promise<void> {
    if (!this.discountInfo) {
      await this.getDiscountCodeInformation();
    }
  }

  discountInfo: DiscountInfo | null = null;
  discountInfoLoading = false;
  async getDiscountCodeInformation(): Promise<void> {
    this.discountInfoLoading = true;
    const response = await PromoCodeService.getDiscountCodeInformation();
    if (response) {
      this.discountInfo = response;
    }
    this.discountInfoLoading = false;
  }

  get checkboxesInvalid(): boolean {
    return !this.isAcknowledged;
  }

  get validateCancellationReasons(): boolean {
    return !!this.selectedCancellationOption;
  }

  get currentPeriodEnd(): string {
    return dayjs(this.subscription.current_period_end).format('MMM DD, YYYY');
  }

  // METHODS
  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext): void {
    if (to.name === 'cancel_subscription' && from.path !== '/my-account/billing') next({ path: '/my-account/billing' });
    next();
  }

  get isFeatureEng2202Enabled(): boolean {
    return isFeatureEnabled(
      optimizelyFeatureFlags,
      'cancellation-discount-modal',
      store.state?.auth?.user?.id.toString()
    );
  }

  get abTestValue(): string | null {
    return this.isFeatureEng2202Enabled && PromoCodeService.isPartOfABTestENG2250(true)
      ? PromoCodeService.AB_TEST_DISCOUNT_DELIVERY
      : null;
  }

  async onClickCancelSubscription(): Promise<void> {
    this.submitted = true;
    if ((this as any).$refs.form.validate() && !this.checkboxesInvalid && this.validateCancellationReasons) {
      if (
        this.isFeatureEng2202Enabled &&
        PromoCodeService.isPartOfABTestENG2250() &&
        !PromoCodeService.isCouponCodeEnabled()
      ) {
        this.isDiscountOfferModalShown = true;
        this.snowplowTrackCancelSubscription(true);
      } else {
        await this.cancelSubscription(false);
      }
    } else {
      this.snowplowTrackCancelSubscription(false);
    }
  }

  async cancelSubscription(skipTracking = false): Promise<void> {
    this.isUnsubscribing = true;
    this.isDiscountOfferModalShown = false;
    this.stripeSubscriptionService
      .cancel(this.subscription.id)
      .then(async () => {
        if (!skipTracking) {
          this.snowplowTrackCancelSubscription(true);
        }
        await this.cancelSubscriptionSuccess();
        this.isUnsubscribing = false;
      })
      .catch(() => {
        this.isUnsubscribingError = true;
      });
  }

  snowplowTrackCancelSubscription(success: boolean): void {
    const user_id = store.state?.auth?.user?.id;
    const account = store.state?.account?.account;
    let reason_text = this.cancellationOtherReason;
    trackCancelSubscription({
      industry_id: account?.industry_id ?? null,
      user_id,
      reason_identifier: this.selectedCancellationOption,
      reason_text,
      frontend_validation_succeeded: success,
      ab_test: success ? this.abTestValue : null,
    });
  }

  async cancelSubscriptionSuccess(): Promise<void> {
    await store.dispatch('layout/setSnackbar', {
      type: 'info',
      icon: 'info-system',
      text: this.$t('fields.account.billing.cancel_subs.page_subs_ok'),
      isSnackbar: false,
      active: true,
    });
    await this.$router.push({ name: 'my_account_tab', params: { tab: 'billing' } });
  }

  onKeepSubscription(): void {
    this.$router.push({ name: 'my_account_tab', params: { tab: 'billing' } });
    const data = {
      industry_id: this.$store.state?.account?.account?.industry_id ?? null,
      user_id: this.$store.state?.auth?.user?.id,
    };
    trackCancelSubscriptionKeepSubscriptionButtonClicked(data);
  }
  // METHODS END

  // Cancel Subscription reasons
  selectedCancellationOption: SnowplowCancellationReasonIdentifier = '' as SnowplowCancellationReasonIdentifier;
  cancellationOtherReason = '';
  cancellationReasons: CancellationReasonsInterface[] = [
    {
      id: 1,
      label: this.$t('fields.account.billing.cancel_subs.page_option_one') as string,
      value: 'missing_features',
      counterArgumentBanner: {
        backgroundColor: '#FFCDD74D',
        title: this.$t('fields.account.billing.cancel_subs.counter_arguments.page_option_one.title') as string,
        iconName: 'communication',
      },
    },
    {
      id: 2,
      label: this.$t('fields.account.billing.cancel_subs.page_option_two') as string,
      value: 'technical_problems',
      counterArgumentBanner: {
        backgroundColor: '#00BFFF1A',
        title: this.$t('fields.account.billing.cancel_subs.counter_arguments.page_option_two.title') as string,
        iconName: 'support',
        actions: [
          {
            id: 1,
            buttonColor: 'primary',
            buttonCta: this.$t('fields.account.billing.cancel_subs.counter_arguments.page_option_two.button') as string,
            buttonLink: this.helpcenterUrlForCounterArgument(),
          },
        ],
      },
    },
    {
      id: 3,
      label: this.$t('fields.account.billing.cancel_subs.page_option_three') as string,
      value: 'bad_outcome',
      counterArgumentBanner: {
        backgroundColor: '#FFCDD74D',
        title: this.$t('fields.account.billing.cancel_subs.counter_arguments.page_option_three.title') as string,
        iconName: 'communication',
      },
    },
    {
      id: 4,
      label: this.$t('fields.account.billing.cancel_subs.page_option_four') as string,
      value: 'not_using_enough',
      counterArgumentBanner: {
        backgroundColor: '#00BFFF1A',
        title: this.$t('fields.account.billing.cancel_subs.counter_arguments.page_option_four.title') as string,
        description: this.$t(
          'fields.account.billing.cancel_subs.counter_arguments.page_option_four.description'
        ) as string,
        iconName: 'idea',
        actions: [
          {
            id: 1,
            buttonColor: 'primary',
            buttonCta: this.$t(
              'fields.account.billing.cancel_subs.counter_arguments.page_option_four.button'
            ) as string,
            buttonLink: this.websiteUrlForCounterArgument(
              'qr-codes-on',
              'https://www.qr-code-generator.com/blog/category/best-practices/'
            ),
          },
        ],
      },
    },
    {
      id: 5,
      label: this.$t('fields.account.billing.cancel_subs.page_option_five') as string,
      value: 'project_finished',
      counterArgumentBanner: {
        backgroundColor: '#FBF3724D',
        title: this.$t('fields.account.billing.cancel_subs.counter_arguments.page_option_five.title') as string,
        description: this.$t(
          'fields.account.billing.cancel_subs.counter_arguments.page_option_five.description'
        ) as string,
        iconName: 'curated-content',
        actions: [
          {
            id: 1,
            buttonColor: 'banana darken-1',
            buttonCta: this.$t(
              'fields.account.billing.cancel_subs.counter_arguments.page_option_five.button'
            ) as string,
            buttonLink: this.websiteUrlForCounterArgument(
              'qr-codes-for',
              'https://www.qr-code-generator.com/blog/category/best-practices/'
            ),
          },
        ],
      },
    },
    {
      id: 6,
      label: this.$t('fields.account.billing.cancel_subs.page_option_six') as string,
      value: 'too_expensive',
      counterArgumentBanner: {
        backgroundColor: '#00BFFF1A',
        title: this.$t('fields.account.billing.cancel_subs.counter_arguments.page_option_six.title') as string,
        description: this.$t(
          'fields.account.billing.cancel_subs.counter_arguments.page_option_six.description'
        ) as string,
        iconName: 'management',
        actions: [
          {
            id: 1,
            buttonColor: 'primary',
            buttonOutlined: true,
            buttonCta: this.$t(
              'fields.account.billing.cancel_subs.counter_arguments.page_option_six.button_left'
            ) as string,
            buttonLink: this.helpcenterUrlForCounterArgument('articles/7665582564365-How-do-I-pause-a-QR-Code-'),
          },
          {
            id: 2,
            buttonColor: 'primary',
            buttonOutlined: true,
            buttonCta: this.$t(
              'fields.account.billing.cancel_subs.counter_arguments.page_option_six.button_right'
            ) as string,
            buttonLink: this.helpcenterUrlForCounterArgument('articles/7665588128909-How-do-I-delete-a-QR-Code-'),
          },
        ],
      },
    },
    {
      id: 7,
      label: this.$t('fields.account.billing.cancel_subs.page_option_seven') as string,
      value: 'unsure_how_to_use',
      counterArgumentBanner: {
        backgroundColor: '#00BFFF14',
        title: this.$t('fields.account.billing.cancel_subs.counter_arguments.page_option_seven.title') as string,
        description: this.$t(
          'fields.account.billing.cancel_subs.counter_arguments.page_option_seven.description'
        ) as string,
        iconName: 'frames',
        actions: [
          {
            id: 1,
            buttonColor: 'primary',
            buttonCta: this.$t(
              'fields.account.billing.cancel_subs.counter_arguments.page_option_seven.button_left'
            ) as string,
            buttonLink: this.helpcenterUrlForCounterArgument('categories/7662957150221-Getting-Started'),
          },
          {
            id: 2,
            buttonColor: 'primary',
            buttonOutlined: true,
            buttonCta: this.$t(
              'fields.account.billing.cancel_subs.counter_arguments.page_option_seven.button_right'
            ) as string,
            buttonLink: this.websiteUrlForCounterArgument('qr-codes-on', 'https://www.qr-code-generator.com/blog/'),
          },
        ],
      },
    },
    {
      id: 8,
      label: this.$t('fields.account.billing.cancel_subs.page_option_eight') as string,
      value: 'other',
    },
  ];

  websiteUrlForCounterArgument(websitePath: string, defaultLink: string): string {
    switch (store.state?.auth?.user.language) {
      case 'de':
        return `https://www.qrcode-generator.de/${websitePath}/`;
      case 'es':
        return `https://es.qr-code-generator.com/${websitePath}/`;
      case 'pt':
        return `https://pt.qr-code-generator.com/${websitePath}/`;
      case 'it':
        return `https://it.qr-code-generator.com/${websitePath}/`;
      case 'fr':
        return `https://fr.qr-code-generator.com/${websitePath}/`;
      default:
        return defaultLink;
    }
  }

  helpcenterUrlForCounterArgument(articlePath: string = ''): string {
    const helpcenterDomain = 'https://support.qr-code-generator.com/hc/';
    switch (store.state?.auth?.user.language) {
      case 'de':
        return `${helpcenterDomain}de/${articlePath}`;
      case 'es':
        return `${helpcenterDomain}es/${articlePath}`;
      case 'pt':
        return `${helpcenterDomain}pt/${articlePath}`;
      case 'it':
        return `${helpcenterDomain}it/${articlePath}`;
      case 'fr':
        return `${helpcenterDomain}fr/${articlePath}`;
      default:
        return `${helpcenterDomain}en-us/${articlePath}`;
    }
  }
}
</script>

<style lang="scss" scoped>
.fix-gap {
  margin-top: 5px !important;
  ::v-deep {
    .v-input__slot {
      margin-bottom: 0 !important;
    }
  }
}
</style>

<style lang="scss">
.radio {
  &__option {
    &--slot {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin-top: 2px;
    }
  }
}
</style>
