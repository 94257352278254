var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{attrs:{"value":_vm.open,"max-width":"572","overlay-color":"neutral lighten-1","overlay-opacity":".80","content-class":"qr-dialog","scrollable":""},on:{"input":_vm.onCloseModal}},[_c('v-card',{staticClass:"pa-2",attrs:{"rounded":""}},[(_vm.isEnterprise)?_c('v-img',{staticClass:"mb-11",attrs:{"src":require("../../../assets/illustrations/modal_account-cancelation-enterprise.png"),"height":"160","contain":""}}):_c('v-img',{staticClass:"mb-11",attrs:{"src":require("../../../assets/illustrations/modal_account-cancelation.png"),"height":"160","contain":""}}),_c('v-card-title',{staticClass:"px-0 pt-0 px-6 text-center d-block"},[_c('h4',{staticClass:"text-h4",domProps:{"textContent":_vm._s(
          _vm.isEnterprise
            ? _vm.$t('fields.account.billing.cancel_subs.modal_title_ent')
            : _vm.$t('fields.account.billing.cancel_subs.modal_title')
        )}})]),(_vm.isEnterprise)?_c('v-card-subtitle',{staticClass:"pt-4 pb-6 px-6 text-center"},[_vm._v(" "+_vm._s(_vm.$t('fields.account.billing.cancel_subs.modal_desc_ent'))+" ")]):_c('v-card-subtitle',{staticClass:"pt-4 pb-6 px-6 text-center"},[_vm._v(" "+_vm._s(_vm.$t('fields.account.billing.cancel_subs.modal_desc'))+" ")]),_c('v-card-actions',{staticClass:"px-0 py-0 justify-center"},[_c('v-btn',{staticClass:"mr-4",attrs:{"name":"btn-cancel-contact-us","outlined":"","ripple":false,"rounded":"","color":"primary"},on:{"click":_vm.onContactUsButtonClick}},[_vm._v(" "+_vm._s(_vm.isEnterprise ? _vm.$t('fields.account.billing.cancel_subs.modal_btn_outline_ent') : _vm.$t('fields.account.billing.cancel_subs.modal_btn_outline'))+" ")]),_c('v-btn',{staticClass:"ml-0",attrs:{"name":"btn-cancel-keep-benefits","depressed":"","ripple":false,"rounded":"","color":"primary"},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.isEnterprise ? _vm.$t('fields.account.billing.cancel_subs.modal_btn_primary_ent') : _vm.$t('fields.account.billing.cancel_subs.modal_btn_primary'))+" ")])],1),(!_vm.isEnterprise)?_c('v-card-actions',{staticClass:"px-0 py-0 mt-6 justify-center"},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"name":"btn-cancel-cta","text":"","ripple":false,"color":"navy"},on:{"click":_vm.onWantToCancelButtonClick}},[_vm._v(" "+_vm._s(_vm.$t('fields.account.billing.cancel_subs.modal_decision_link'))+" ")])],1):_vm._e(),(!_vm.isEnterprise)?_c('Alert',{staticClass:"subscription-cancel__alert mt-6",attrs:{"outlined":"","type":"info","icon":"info-system","regular-title":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.$t('fields.account.billing.cancel_subs.modal_note', {
              plan_title: _vm.$t(_vm.planName),
              subs_end: _vm.subscription.current_period_end.format('MMM DD, YYYY'),
            })
          )}})]},proxy:true}],null,false,407037283)}):_vm._e(),_c('Dismiss',{attrs:{"absolute":"","top":"","right":""},on:{"click":function($event){$event.stopPropagation();return _vm.onCloseModal.apply(null, arguments)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }