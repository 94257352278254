export type GTM = { push: (data: object) => void };

export function trackCheckoutNowButtonPricingPageStep1(
  gtm: GTM | undefined,
  planName: string,
  planPrice: string | number
): void {
  gtm?.push({
    event: 'upgrade_cta',
    click_text: 'Check Out Now',
    funnel_step_number: '1',
    funnel_step_name: 'Plans and Pricing',
    subscription_tier: planName,
    subscription_cost: planPrice,
    subscription_term: 'Annual',
    cta_location: 'Sign Up',
    brand: 'QRCG',
  });
}

export function trackCompletePurchaseButtonStep2(
  gtm: GTM | undefined,
  planName: string,
  planPrice: string | number
): void {
  gtm?.push({
    event: 'upgrade_cta',
    click_text: 'Check Out Now',
    funnel_step_number: '2',
    funnel_step_name: 'Payment',
    subscription_tier: planName,
    subscription_cost: planPrice,
    subscription_term: 'Annual',
    cta_location: 'Sign Up',
    brand: 'QRCG',
  });
}

export function trackPaymentFormError(gtm: GTM | undefined, planName: string): void {
  gtm?.push({
    event: 'Payment Form Error',
    subscription_tier: planName,
  });
}
