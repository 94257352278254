import OptimizelyService from '@/Services/OptimizelyService';
import { Team, User } from '@/classes/auth';
import { Subscription } from '@/classes/stripe';
import store from '@/store';
import dayjs from 'dayjs';

interface OptimizelyAttributes {
  country_from_registration: string;
  plan: string;
  signup_language: string;
  active_sub_without_renewal: boolean;
  created_date_from_checkout: string;
  subscription_ends_at: dayjs.Dayjs | null;
  payment_method_type: string;
  user_trial_state: number;
  legal_entity: string; // 'b2b' || 'b2c'
}

interface OptimizelyTrackingData {
  type: string;
  attributes: OptimizelyAttributes;
}

export default function init(user: User, team: Team): void {
  if (OptimizelyService.optimizelyIsNotInitialized()) return;

  const activeSubscription: Subscription = store.state.subscription.activeSubscription;
  const user_trial_state: number = store.state.account.account?.trial || 0;
  let planName: string;

  switch (team.plan_name) {
    case 'Starter':
      planName = 'paid1';
      break;
    case 'Advanced':
      planName = 'paid2';
      break;
    case 'Professional':
      planName = 'paid3';
      break;
    default:
      planName = '';
  }

  const data: OptimizelyTrackingData = {
    type: 'user',
    attributes: {
      country_from_registration: team.country_from_registration ?? team.country,
      plan: planName,
      signup_language: user.language, // TODO: this is potentially not correct CNV-2553
      active_sub_without_renewal: isSubscriptionWithoutRenewal(activeSubscription),
      created_date_from_checkout: team.created_at,
      subscription_ends_at: activeSubscription.ends_at || null,
      payment_method_type: team.payment_method_type,
      user_trial_state: user_trial_state,
      legal_entity: team.legal_entity,
    },
  };

  const payload: object = data;

  window.optimizelyCustomJsAttributes = payload;
  // @ts-ignore
  window.optimizely.push(payload);
}

export function disableOptimizely(): void {
  if (OptimizelyService.optimizelyIsNotInitialized()) return;
  // @ts-ignore
  window.optimizely?.push({
    type: 'disable',
  });
}

const isSubscriptionWithoutRenewal = (subscription: Subscription): boolean => {
  return subscription?.id !== 0 && subscription.ends_at !== null;
};
