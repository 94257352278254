import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route, RouteMeta } from 'vue-router';
import store from '@/store';
import { AppRoutes } from '@/router/routes';
import globalMiddleware from '@/middleware/global';
import seoMiddleware from '@/middleware/seo';
import { CURRENCY_LIST, CurrencyCode } from '@/Models/Localization';
import middlewarePipeline from '@/middleware/middlewarePipeline';
import snowplowMiddleware from '@/middleware/snowplow';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: AppRoutes,
});

export async function beforeEach(to: Route, from: Route, next: NavigationGuardNext): Promise<() => void> {
  store.commit('layout/setRouteLoading', true);

  if (
    to.query.currency &&
    CURRENCY_LIST.includes(to.query.currency as CurrencyCode) &&
    process.env.VUE_APP_STAGE !== 'production'
  ) {
    const currentTeam = store.state.auth.team;

    currentTeam.currency = to.query.currency as CurrencyCode;

    await store.dispatch('auth/setTeam', currentTeam);
  }

  // FIXME this is a mess and we only have our own custom middlewares and none per route
  // They should just be inlined in the middleware pipeline!
  const middleware = [globalMiddleware, (to.meta as RouteMeta).middleware, seoMiddleware, snowplowMiddleware].flat(1);

  const context = {
    to,
    from,
    next,
    router,
    store,
  };

  return (middleware[0] as Function)({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
}

router.beforeEach((to, from, next) => beforeEach(to, from, next));

export default router;
