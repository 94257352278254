import AppConfig from '@/AppConfig';
import type StripeCheckoutService from '@/Services/Subscription/StripeCheckoutService';
import type { StripePaymentMethodService } from '@/Services/Subscription/StripePaymentMethodService';
import axios from 'axios';
import VueRouter from 'vue-router';
import { PaymentRequestPaymentMethodEvent } from '@stripe/stripe-js';
import { AxiosError } from 'axios/index';
import * as Sentry from '@sentry/vue';

export async function orchestrateWalletFlow(
  stripeCheckoutService: StripeCheckoutService,
  stripePaymentMethodService: StripePaymentMethodService,
  router: VueRouter,
  selectedPlan: string,
  selectedPlanId: string,
  checkoutType: string,
  paymentMethodId: string,
  event: PaymentRequestPaymentMethodEvent
): Promise<void> {
  // save the payment method and create a customer in stripe
  await axios
    .post(AppConfig.getAPIBaseUrl() + '/subscription/stripe/payment-methods', {
      new_payment_method_id: paymentMethodId,
      legal_entity: stripeCheckoutService.legalEntity,
      customer: stripeCheckoutService.customer,
    })
    .catch((error: AxiosError) => {
      Sentry.captureException(error);
    });

  // create a setup intent
  const { data: setupIntent } = await axios({
    method: 'post',
    url: AppConfig.getAPIBaseUrl() + '/subscription/stripe/payment-methods/create',
    data: {
      payment_method_type: stripePaymentMethodService.paymentType,
      payment_method_id: paymentMethodId,
    },
  });

  // persist the desired plan for activation after a successful payment
  await axios({
    method: 'post',
    url: AppConfig.getAPIBaseUrl() + '/team/incomplete-subscription/create',
    data: {
      plan_id: selectedPlanId,
      setup_intent_id: setupIntent.id,
      payment_method_type: 'wallet',
    },
  });

  // No need for a redirect URL as with PayPal
  // Instead, confirm the setup intent directly
  const { error } = await stripePaymentMethodService.stripe.confirmCardSetup(setupIntent.client_secret, {
    payment_method: paymentMethodId,
  });

  const queryParams = {
    checkoutType: checkoutType,
    plan: selectedPlan.toLowerCase(),
    setup_intent: setupIntent.id,
    paymentType: 'wallet',
  };

  if (error) {
    event.complete('fail');
    throw new Error(error.message);
  } else {
    event.complete('success');

    await router.push({ path: '/processing', query: queryParams });
  }
}
