<template>
  <div id="checkout">
    <v-row justify="center">
      <v-col md="12" xl="10" offset-xl="1">
        <!-- ALERTS -->
        <v-row>
          <v-col cols="12">
            <Alert
              v-if="currentPlanTranslationKey && currentPlanEndDate && checkoutType === 'downgrade'"
              dense
              outlined
              type="warning"
              icon="warning-system"
              regular-title
            >
              <template #title>
                <span
                  v-html="
                    $t('fields.checkout.header_downgrade_plan_info', {
                      current_plan_title: $t(`fields.${currentPlanTranslationKey}`),
                      expire_date: $t(currentPlanEndDate),
                      new_plan_title: selectedPlanTitle,
                    })
                  "
                />
              </template>
            </Alert>
          </v-col>
        </v-row>
        <!-- ALERTS END -->
        <!-- PAYMENT FORM -->
        <div>
          <Alert
            v-show="formErrorMessage"
            id="errorAlert"
            ref="errorAlert"
            data-test="qr-payment-form-error-alert"
            outlined
            type="error"
            icon="forbidden-system"
          >
            <template #title>
              <div style="display: flex; justify-content: space-between; width: 100%; gap: 20px">
                <span v-text="formErrorMessage" />
                <button
                  v-if="!formStripeErrorMessage"
                  type="button"
                  class="alert-fix-button"
                  @click="scrollToErrorNew(errorContainer)"
                >
                  {{ $t('fields.error.cta_button_fix_it') }}
                </button>
              </div>
            </template>
          </Alert>
          <header-component
            :checkout-type="checkoutType"
            :translation-key="selectedPlan.translationKey"
            :is-monthly-flex-plan="selectedPlan.isMonthlyFlexPlan"
          ></header-component>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="8" xl="8" class="checkout__payment">
              <ValidationObserver ref="observer" slim>
                <v-stepper v-model="stepper" class="checkout__container">
                  <v-stepper-items>
                    <!-- STEP 1: PAYMENT METHOD SELECTION -->
                    <v-stepper-content step="2" class="checkout__step-container">
                      <payment-method-component
                        :payment-methods-to-show="stripePaymentMethodService.paymentMethodsToShow"
                        :is-payment-form-loaded="stripePaymentMethodService.isPaymentFormLoaded"
                        :payment-methods-loading="stripePaymentMethodService.paymentMethodsLoading"
                        :selected-payment-type="stripePaymentMethodService.paymentType"
                        :wallets-available="stripePaymentMethodService.walletsAvailable"
                        :selected-plan="selectedPlan"
                        @paymentTypeChanged="onPaymentMethodChange($event)"
                      ></payment-method-component>
                    </v-stepper-content>
                    <!-- STEP 1: PAYMENT METHOD SELECTION END -->
                    <v-divider v-if="stripePaymentMethodService.paymentType === 'card'" class="mb-3" />

                    <!-- STEP 2: PAYMENT INFORMATION FORM -->
                    <v-stepper-content
                      v-show="stripePaymentMethodService.paymentType === 'card'"
                      id="checkoutPaymentInformationContainer"
                      ref="payment_information"
                      step="2"
                      class="checkout__step-container"
                    >
                      <stripe-payment-information
                        ref="stripePaymentInformation"
                        :form-stripe-error-message="formStripeErrorMessage"
                        :stripe-payment-method-service="stripePaymentMethodService"
                      />
                    </v-stepper-content>
                    <!-- STEP 2: PAYMENT INFORMATION FORM END -->

                    <v-divider class="mb-3" />

                    <!-- STEP 2: BILLING ADDRESS -->
                    <v-stepper-content
                      id="checkoutBillingAddressContainer"
                      step="2"
                      class="checkout__step-container pb-1"
                    >
                      <AddressWithCombobox
                        ref="addressWithCombobox"
                        :stripe-checkout-service="stripeCheckoutService"
                        :stripe-payment-method-service="stripePaymentMethodService"
                        :team="team"
                      />

                      <email-and-notes :checkout-service="stripeCheckoutService"></email-and-notes>
                    </v-stepper-content>
                    <!-- STEP 2: BILLING ADDRESS END -->

                    <!-- STEP 2: PAYMENT FORM SUBMIT -->
                    <v-stepper-content step="2" class="checkout__step-container pt-0">
                      <v-row v-if="isUserOnFtcCheckboxAbTest()" justify="center">
                        <v-col md="11">
                          <QrCheckbox
                            id="acceptTosCheckbox"
                            v-model="tosConfirmed"
                            color="navy"
                            :error-state="!tosConfirmed && displayTosCheckboxError"
                          >
                            <template #label>
                              <span class="accept-tos-checkbox-label d-inline-block">
                                {{ $t('fields.checkout.form.tos_confirmation_checkbox.label') }}
                                <a
                                  href="https://www.qr-code-generator.com/company/terms/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  @click.stop
                                >
                                  {{ $t('fields.checkout.form.tos_confirmation_checkbox.terms_of_service_label') }}</a
                                >,
                                <a
                                  href="https://www.qr-code-generator.com/company/privacy-policy/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  @click.stop
                                >
                                  {{ $t('fields.checkout.form.tos_confirmation_checkbox.data_policy_label') }}</a
                                >,
                                <a
                                  href="https://www.qr-code-generator.com/company/acceptable-use-policy/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  @click.stop
                                >
                                  {{
                                    $t('fields.checkout.form.tos_confirmation_checkbox.acceptable_use_policy_label')
                                  }}</a
                                >
                                {{ $t('fields.checkout.form.tos_confirmation_checkbox.word_and') }}
                                <a
                                  href="https://www.qr-code-generator.com/company/data-processing-agreement/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  @click.stop
                                >
                                  {{
                                    $t('fields.checkout.form.tos_confirmation_checkbox.data_processing_agreement_label')
                                  }}</a
                                >
                                {{ $t('fields.checkout.form.tos_confirmation_checkbox.word_and') }}
                                <a
                                  href="https://www.qr-code-generator.com/company/automatic-renewal-terms/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  @click.stop
                                >
                                  {{
                                    $t('fields.checkout.form.tos_confirmation_checkbox.automatic_renewal_terms_label')
                                  }}</a
                                >.
                              </span>
                            </template>
                          </QrCheckbox>

                          <error-message
                            :invalid="!tosConfirmed && displayTosCheckboxError"
                            class="ml-8 mt-1"
                            data-test="checkout-tos-approval-checkbox-error"
                          >
                            {{ $t('fields.checkout.form.tos_confirmation_error') }}
                          </error-message>
                        </v-col>
                      </v-row>
                      <v-row justify="center" align="center">
                        <v-col cols="auto">
                          <v-btn
                            depressed
                            rounded
                            :ripple="false"
                            color="primary"
                            x-large
                            data-test="qr-payment-complete-purchase-button"
                            :loading="isSaving"
                            :disabled="!isSubmitPossible"
                            @click="throttleSubmit"
                          >
                            {{ $t('fields.checkout.form.submit_button') }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-stepper-content>
                    <!-- STEP 2: PAYMENT FORM SUBMIT END -->

                    <!-- STEP 2: PAYMENT FORM SUBMIT -->
                    <div class="checkout__payment-safety">
                      <v-row justify="center" align="center">
                        <h5 class="text-h5 checkout__payment-safety-header">
                          {{ $t('fields.checkout.form.security_info_header') }}
                        </h5>
                        <p class="checkout__payment-safety-text">
                          {{ $t('fields.checkout.form.security_info_text') }}
                        </p>
                      </v-row>
                    </div>
                    <!-- STEP 2: PAYMENT FORM SUBMIT END -->
                  </v-stepper-items>
                </v-stepper>
              </ValidationObserver>
            </v-col>
            <!-- PAYMENT FORM END -->

            <!-- SIDEBAR -->
            <PaymentFormSidebar
              :selected-plan="selectedPlan"
              :stripe-checkout-service="stripeCheckoutService"
              :checkout-type="checkoutType"
              @totalPriceChanged="onTotalPriceChanged($event)"
            />
            <!-- SIDEBAR END -->
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import AppConfig from '@/AppConfig';
import VeeValidateRefs from '@/Models/VeeValidateRefs';
import EventService from '@/Services/EventService';
import { trackCompletePurchaseButtonStep2, trackPaymentFormError } from '@/Services/GoogleTagManager';
import OptimizelyService from '@/Services/OptimizelyService';
import { trackCompletePurchaseButton } from '@/Services/Snowplow/SnowplowService';
import StripeCheckoutService from '@/Services/Subscription/StripeCheckoutService';
import StripeSubscriptionService from '@/Services/Subscription/StripeSubscriptionService';
import { Team } from '@/classes/auth';
import { Plan, Subscription, SubscriptionState } from '@/classes/stripe';
import Alert from '@/components/Alert.vue';
import HelpTooltip from '@/components/HelpTooltip.vue';
import Icon from '@/components/Icons/Icon.vue';
import AddressWithCombobox from '@/components/PaymentForm/AddressWithCombobox.vue';
import EmailAndNotes from '@/components/PaymentForm/EmailAndNotes.vue';
import HeaderComponent from '@/components/PaymentForm/Header.vue';
import PaymentFormSidebar from '@/components/PaymentForm/PaymentFormSidebar.vue';
import PaymentMethodComponent from '@/components/PaymentForm/PaymentMethod.vue';
import StripePaymentInformation from '@/components/PaymentForm/StripePaymentInformation.vue';
import store from '@/store';
import { AuthState } from '@/store/auth';
import * as Sentry from '@sentry/vue';
import axios, { AxiosError } from 'axios';
import { debounce } from 'lodash';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { orchestratePaypalFlow } from './paypal-flow';
import { orchestrateWalletFlow } from './wallet-flow';
import { PaymentIntentResult, PaymentRequestPaymentMethodEvent } from '@stripe/stripe-js';
import countries from '@/Models/Static/countries.json';
import Country from '@/Models/Static/Country';
import { TranslateResult } from 'vue-i18n';
import QrCheckbox from '@/components/Input/QrCheckbox.vue';

@Component({
  components: {
    QrCheckbox,
    Icon,
    Alert,
    HelpTooltip,
    AddressWithCombobox,
    StripePaymentInformation,
    PaymentFormSidebar,
    HeaderComponent,
    PaymentMethodComponent,
    EmailAndNotes,
    ValidationObserver,
    ValidationProvider,
  },
  computed: {
    ...mapState<SubscriptionState>('subscription', {
      activeSubscription: (state: SubscriptionState) => state.activeSubscription,
      activePlan: (state: SubscriptionState) => state.activePlan,
      subscriptionPlan: (state: SubscriptionState) => state.selectedPlan,
    }),
    ...mapState<AuthState>('auth', {
      team: (state: AuthState) => new Team(state.team),
    }),
  },
})
export default class PaymentForm extends Vue {
  @Prop({ default: '', type: String })
  planId!: string;

  @Prop()
  planName: string | undefined;

  @Prop({ default: '' })
  countryIso2!: string;

  team!: Team;
  activeTab?: any = 0;
  // state from vuex
  activeSubscription!: Subscription;
  // state from vuex
  activePlan!: Plan;
  // state from vuex
  subscriptionPlan!: Plan;
  // Handles business logic and form state
  stripeCheckoutService: StripeCheckoutService = new StripeCheckoutService();
  // Handles payment method logic and form state
  stripeSubscriptionService: StripeSubscriptionService = new StripeSubscriptionService();
  // selected subscription plan
  selectedPlan: Plan = new Plan();
  // This is the stepper indicator, actually it is unused, because we fake the steps
  stepper = 2;
  // Prevents to many vat rate calculations
  throttleUpdateVatRate = debounce(
    async () => {
      this.updateVatRateDebounceIsPending = true;
      await this.stripeCheckoutService.updateCustomerVatRate();
      this.updateVatRateDebounceIsPending = false;
    },
    1000,
    {
      leading: false,
      trailing: true,
    }
  );
  // Prevents double click issues, only last click counts
  throttleSubmit = debounce(this.submit, 300, { leading: false, trailing: true });
  // Prevents double click issues and shows state of saving
  isSaving = false;
  checkoutType = 'new';
  isUpdating = true;

  formErrorMessage = '';
  formStripeErrorMessage = false;

  walletsAvailable = {};

  // needed for Google Apple Pay
  totalPrice: any = 0;
  priceNeedsRecalculation: boolean = this.totalPrice === 0;
  priceHasBeenRecalculated: boolean = !this.priceNeedsRecalculation;
  updateVatRateDebounceIsPending: boolean = false;
  errorContainer = '';

  // Handles payment method logic and form state
  stripePaymentMethodService = this.$store.state.subscription.stripePaymentMethodService;

  $refs!: {
    observer: VeeValidateRefs;
    // Container for payment-information
    payment_information: any;
    errorAlert: any;
    stripePaymentInformation: any;
    addressWithCombobox: AddressWithCombobox;
  };

  // Just legal entities ready for selection
  legalEntityB2C: string = 'b2c';

  tosConfirmed: boolean = false;
  displayTosCheckboxError: boolean = false;

  @Watch('selectedPlan.id', { deep: false, immediate: false })
  onSelectedPlanUpdate(selectedPlan: Plan): void {
    this.subscriptionCheckoutType(selectedPlan.id);
  }

  @Watch('stripePaymentMethodService.walletsAvailable', { deep: false, immediate: false })
  onAvailableWalletsUpdate(): void {
    if (this.stripePaymentMethodService.walletsAvailable) {
      this.walletsAvailable = this.stripePaymentMethodService.walletsAvailable;
    }
  }

  @Watch('activePlan.id', { deep: true, immediate: false })
  onActivePlanUpdate(): void {
    if (this.subscriptionPlan.id === this.activePlan.id && !this.subScriptionHasIncompletePayment()) {
      this.redirectToPlanSelection();
    }
  }

  get selectedPlanTitle(): TranslateResult {
    return this.selectedPlan.isMonthlyFlexPlan
      ? this.$t('fields.pricing_table.flex_plan_title_monthly')
      : this.$t(`fields.${this.selectedPlan.translationKey}`);
  }

  // Setup the components needed data, like customer data.
  async created(): Promise<void> {
    await this.loadPlan();
    await this.handlePlanState();

    // Handle country overwrite via URL prop
    if (this.countryIso2.length > 0) {
      await this.stripeCheckoutService.selectCountry(this.countryIso2);
    }

    this.handlePrefillData();
    // set active tab to b2c, if user is b2c
    if (this.team.legal_entity === 'b2c') {
      this.activeTab = 1;
      this.stripeCheckoutService.legalEntity = this.legalEntityB2C;
    }

    this.subscriptionCheckoutType(this.selectedPlan.id);

    await this.getAllowedPaymentMethodsForUser();
    this.isUpdating = false;

    EventService.createEvent(EventService.VISITED_CHECKOUT, {
      paramPlanId: this.planId || null,
      paramCountryIso: this.countryIso2 || null,
    });

    await this.$nextTick();
    this.initOptimizelyTracking();
  }

  async mounted(): Promise<void> {
    this.initWallets();
    // Mount the payment form, we do this here because refs is not available in created()!
    await this.rebuildStripeForm(this.stripePaymentMethodService.paymentType);
    await this.$nextTick();
  }

  beforeDestroy(): void {
    this.removeTrackingElement();
  }

  initOptimizelyTracking(): void {
    // Custom event tracking
    OptimizelyService.trackEvent(`checkout_page_view_${this.selectedPlan.planName.toLowerCase()}`);
    this.createTrackingElement();
  }

  onPaymentMethodChange(event: string): void {
    this.isUpdating = true;
    this.$store.commit('subscription/setStripePaymentMethodServicePaymentType', event);
    this.isUpdating = false;
  }

  async loadPlan(): Promise<void> {
    let plan: Plan | undefined;
    if (this.planId) {
      plan = await this.stripeSubscriptionService.loadPlanById(this.planId);
    }
    if (this.planName) {
      plan = this.stripeSubscriptionService.getStandardPlanByName(this.planName);
    }

    if (plan) await this.$store.dispatch('subscription/setSubscriptionPlan', plan);
  }

  createTrackingElement(): void {
    // We create a hidden input with a class name which includes the plan name
    // We use it for page view tracking inside optimizely dashboard
    const input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('class', `checkout_page_view_${this.selectedPlan.planName.toLowerCase()}`);
    document.body.appendChild(input);
  }

  removeTrackingElement(): void {
    const element = document.querySelector<HTMLElement>(
      `.checkout_page_view_${this.selectedPlan.planName.toLowerCase()}`
    );
    if (element) {
      element.remove(); // Removes the div with the 'div-02' id
    }
  }

  subScriptionHasIncompletePayment(): boolean {
    return ['incomplete', 'past_due'].includes(this.activeSubscription.status);
  }

  async getAllowedPaymentMethodsForUser(): Promise<void> {
    const country = this.getUserCountryForPaymentMethods();
    if (country === '') {
      await this.stripePaymentMethodService.getAllowedPaymentMethodsForUser(
        this.stripeCheckoutService.location.country_iso2
      );
    } else {
      await this.stripePaymentMethodService.getAllowedPaymentMethodsForUser(country);
    }
  }

  //Always filter payment method depend on the counry from registration of the user, is this one is empty then we use the country from the location
  getUserCountryForPaymentMethods(): string {
    const countryFromRegistration = this.team.country_from_registration;
    let result: Country | undefined;
    if (!countryFromRegistration) {
      return '';
    }
    result = countries.find((item: Country) => item.country_iso2 === countryFromRegistration);
    return result ? result.country_iso2 : '';
  }

  async redirectToPlanSelection(): Promise<void> {
    await this.$router.push({ name: 'checkout_plan_selection' });
  }

  // Mainly handling of plan state, is a plan selected or not and how to react if not
  async handlePlanState(): Promise<void> {
    // Without a plan (or the same as active) a checkout is not possible so we redirect the customer to the selection page
    if (
      (!this.subscriptionPlan?.id || this.subscriptionPlan?.id === this.activePlan.id) &&
      !this.subScriptionHasIncompletePayment()
    ) {
      await this.redirectToPlanSelection();
    }
  }

  get currentPlanTranslationKey(): string | undefined {
    return this.stripeSubscriptionService.findPlan(this.activeSubscription.plan_id)?.translationKey;
  }

  get currentPlanEndDate(): string {
    return this.activeSubscription.current_period_end.format('MMMM D, YYYY');
  }

  // We fill the form for the customer to ease the pain of filling out
  handlePrefillData(): void {
    // Load plan from vuex
    Object.assign(this.selectedPlan, new Plan(this.subscriptionPlan));

    // Init customer data for prefilling the form
    this.stripeCheckoutService.initCustomerData();

    // Setup subscription for the service
    this.stripeSubscriptionService.initSubscription();
  }

  // Rebuild stripe elements form an exception is send_invoice which doesn't need stripe elements
  async rebuildStripeForm(paymentMethodType: string): Promise<void> {
    // Wait for the next tick to ensure the form is loaded and the custom elements could be injected
    await this.$nextTick();
    switch (paymentMethodType) {
      case 'card':
        this.stripePaymentMethodService.initCardForm();
        break;
      case 'sepa_debit':
        this.stripePaymentMethodService.initSEPAForm();
        break;
    }
  }

  initWallets(): void {
    this.stripePaymentMethodService.initWallets();
  }

  async forceLogout(): Promise<void> {
    await this.$store.dispatch('auth/logout');
    const authUrl: string = AppConfig.getAuthServiceUrl() || '';
    window.location.href = `${authUrl}/logout`;
  }

  /**
   * How this works:
   * - Create a subscription with incomplete status
   * - Use intent from the latest_invoice of this subscription and hook into stripe element payment form
   * - Trigger a card payment and save the payment method in stripe
   * - Save payment method as well in our API // TODO can later on be outsourced to webhooks maybe
   * - Redirect on success, stay on failure
   */
  async submit(): Promise<void> {
    const language = store.state?.auth?.user?.language;
    const user_id = store.state?.auth?.user?.id;
    const account = store.state?.account?.account;
    const price_currency_displayed = store.state?.auth?.team?.currency;
    trackCompletePurchaseButton({
      industry_id: account?.industry_id ?? null,
      user_id,
      price_currency_displayed,
      language_code: language,
    });
    trackCompletePurchaseButtonStep2(window.dataLayer, this.selectedPlan.planName, this.selectedPlan.price.price);

    this.formStripeErrorMessage = false;
    this.formErrorMessage = '';

    if ((await this.$refs.observer.validate()) && this.selectedPlan.id && this.isSubmitPossible) {
      this.displayTosCheckboxError = !this.tosConfirmed;
      if (this.isUserOnFtcCheckboxAbTest() && this.displayTosCheckboxError) {
        this.formErrorMessage = this.$t('fields.checkout.form.tos_confirmation_error').toString();
        this.errorContainer = 'acceptTosCheckbox';
        this.scrollToErrorNew(this.errorContainer);
        return;
      }

      if (this.stripePaymentMethodService.paymentType === 'card') {
        this.$refs.stripePaymentInformation.hasClass();
      }

      if (this.stripeCheckoutService.location.vat_rate === null) {
        Sentry.withScope((scope) => {
          scope.setExtra('country', this.stripeCheckoutService.location);
          Sentry.captureMessage('[error] dbip fail');
        });

        this.formErrorMessage = this.$t('fields.checkout.form.invalid_tax').toString();
        this.scrollToErrorNew('checkoutPaymentInformationContainer');
        return;
      }

      this.isSaving = true;
      this.$store.commit('layout/toggleGlobalLoader', true);
      this.$store.commit('layout/toggleGlobalOverlay', true);

      if (this.stripePaymentMethodService.paymentType === 'paypal') {
        try {
          await orchestratePaypalFlow(
            this.stripeCheckoutService,
            this.stripePaymentMethodService,
            this.selectedPlan.planName,
            this.selectedPlan.id,
            this.checkoutType
          );
        } catch (error) {
          this.errorContainer = 'checkoutPaymentInformationContainer';
          this.formErrorMessage = String(this.$t('fields.modals.support.submit.error'));
          Sentry.captureException(error);
        }
      } else if (this.isWalletPaymentMethod()) {
        // they clicked submit
        // final update of the payment request to make sure we have the correct price
        if (this.priceHasBeenRecalculated && !this.updateVatRateDebounceIsPending) {
          this.stripePaymentMethodService.paymentRequest?.update({
            total: {
              label: this.selectedPlan.planName.toLowerCase(),
              amount: this.totalPrice,
            },
          });

          // Show the modal
          this.stripePaymentMethodService.paymentRequest?.show();

          this.stripePaymentMethodService.paymentRequest?.on(
            'paymentmethod',
            async (event: PaymentRequestPaymentMethodEvent) => {
              const paymentMethod = event.paymentMethod;

              if (paymentMethod) {
                // they clicked, this is where the flow should commence
                // orchestration and all that
                try {
                  await orchestrateWalletFlow(
                    this.stripeCheckoutService,
                    this.stripePaymentMethodService,
                    this.$router,
                    this.selectedPlan.planName,
                    this.selectedPlan.id,
                    this.checkoutType,
                    paymentMethod.id,
                    event
                  );
                } catch (error) {
                  this.errorContainer = 'checkoutPaymentInformationContainer';
                  this.formErrorMessage = String(this.$t('fields.modals.support.submit.error'));
                  Sentry.captureException(error);
                }
              }
            }
          );
        }
      } else {
        /**
         * We need a default payment method for a subscription.
         * A subscription create must therefore create a new payment method, attach it to the customer as default
         */
        const paymentMethodStoreResult = await this.submitPaymentMethod();
        const response = paymentMethodStoreResult?.response;
        const type = this.checkoutType;

        switch (paymentMethodStoreResult?.status ?? paymentMethodStoreResult?.response?.status) {
          case 422: {
            if ('customer.tax_id_data.value' in response.data.errors) {
              this.formErrorMessage = this.$t('fields.checkout.form.invalid_vat_id').toString();
            } else {
              this.formErrorMessage = this.$t('fields.checkout.form.invalid').toString();
            }

            this.errorContainer = 'checkoutBillingAddressContainer';

            break;
          }
          case 402: {
            this.formStripeErrorMessage = true;
            // Check if card is reported lost or stolen and force logout the user
            if (['lost_card', 'stolen_card'].includes(response.data.decline_code)) {
              await this.forceLogout();
            }
            const errorMessage = this.$t(
              'fields.checkout.form.stripe_errors.' + (response.data.decline_code || response.data.error_code)
            ).toString();
            this.formErrorMessage = this.$t(errorMessage).toString();

            break;
          }
          case 200: {
            await this.submitSubscription(this.selectedPlan.id)
              .then(async (subscriptionResult: any) => {
                OptimizelyService.trackEvent('purchase_complete');
                OptimizelyService.trackEvent(`purchase_complete_${this.selectedPlan.planName.toLowerCase()}`);

                if (this.team.legal_entity) {
                  OptimizelyService.trackEvent(`purchase_complete_as_${this.team.legal_entity}`);
                } else {
                  OptimizelyService.trackEvent('purchase_complete_as_unknown');
                }

                // TODO use switch here for the different payment types later on
                // Everything is good, redirect to success page!
                if (subscriptionResult && this.stripePaymentMethodService.paymentType === 'card') {
                  const confirmPaymentResult: PaymentIntentResult =
                    await this.stripePaymentMethodService.stripe.confirmCardPayment(subscriptionResult);

                  if (!confirmPaymentResult.error) {
                    await this.$router.push({
                      name: 'checkout_payment_complete',
                      params: { type },
                      query: { plan: this.selectedPlan.planName.toLowerCase() },
                    });
                  } else {
                    // Report Stripe confirmPayment errors
                    const error = confirmPaymentResult.error;

                    if (error) {
                      let errorMessageKey = '';
                      let extraData = {};

                      if (error.decline_code) {
                        errorMessageKey = 'fields.checkout.form.stripe_errors.' + error.decline_code;
                        extraData = { decline_code: error.decline_code };
                      } else if (error.code) {
                        errorMessageKey = 'fields.checkout.form.stripe_errors.' + error.code;
                        extraData = { code: error.code };
                      }

                      if (errorMessageKey) {
                        this.formErrorMessage = this.$t(errorMessageKey).toString();
                      } else {
                        this.checkAddressErrors();
                        extraData = {
                          error_code: error.code,
                          error_type: error.type,
                          payment_intent_id: error?.payment_intent?.id,
                          error_message: error.message,
                        };
                      }

                      Sentry.withScope((scope) => {
                        scope.setExtras(extraData);
                        Sentry.captureMessage('[PaymentForm] Payment Method declined by Stripe');
                      });

                      this.errorContainer = 'checkoutPaymentInformationContainer';
                    }
                  }
                } else {
                  await this.$router.push({
                    name: 'checkout_payment_complete',
                    params: { type },
                    query: { plan: this.selectedPlan.planName.toLowerCase() },
                  });
                }
              })
              .catch(() => {
                this.checkAddressErrors();
                this.errorContainer = 'checkoutPaymentInformationContainer';
              });
            break;
          }
          default: {
            if (this.stripePaymentMethodService.stripeErrorMessage) {
              this.formErrorMessage = this.$t(
                'fields.checkout.form.stripe_errors.' + this.stripePaymentMethodService.stripeError
              ).toString();
              this.errorContainer = 'checkoutPaymentInformationContainer';
              Sentry.withScope((scope) => {
                scope.setExtra('error_code', this.stripePaymentMethodService.stripeError);
                Sentry.captureMessage('[PaymentForm] Unhandled failed payment method with Stripe API or checkout-api');
              });
            }
          }
        }
      }
      this.isSaving = false;
    } else {
      this.checkAddressErrors();
      this.errorContainer = 'checkoutBillingAddressContainer';
    }

    if (this.formErrorMessage !== '') {
      // Send GTM event on all errors
      trackPaymentFormError(window.dataLayer, this.selectedPlan.planName);
      this.scrollToError(this.$refs.errorAlert);
    }

    this.$store.commit('layout/toggleGlobalLoader', false);
    this.$store.commit('layout/toggleGlobalOverlay', false);
  }

  scrollToErrorNew(id: string, topNegativeOffset: number = -150): void {
    // document?.getElementById('errorAlert')?.classList.add('alert--fixed');
    const yOffset = topNegativeOffset;
    const element = document.getElementById(id);
    if (element) {
      const y = (element as HTMLElement).getBoundingClientRect()?.top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }

  // Scroll to the nearest error from submit upwards
  scrollToError(element: any = null): void {
    if (element) {
      this.$vuetify.goTo(element, { offset: 240 });
    } else {
      const entry: any = Object.keys(this.$refs.observer.errors)
        .reverse()
        .find((entry: any) => {
          return this.$refs.observer.errors[entry].length > 0;
        });

      if (typeof entry !== 'undefined' && entry in this.$refs) {
        //@ts-ignore
        this.$vuetify.goTo(this.$refs[entry], { offset: 240 });
      }
    }
  }

  // Save the payment method as new default
  async submitPaymentMethod(): Promise<any> {
    return this.stripePaymentMethodService.savePaymentMethod({
      legal_entity: this.stripeCheckoutService.legalEntity,
      customer: this.stripeCheckoutService.customer,
    });
  }

  // Subscribe to the new plan, either an update or a complete new one
  async submitSubscription(planId: string): Promise<any> {
    const ss = this.stripeSubscriptionService;

    if (!ss.isPlanUpcoming(planId)) {
      return await axios({
        method: ss.subscription.id ? 'patch' : 'post',
        url: AppConfig.getAPIBaseUrl() + '/subscription/stripe/' + (ss.subscription.id ? planId : ''),
        data: {
          plan_id: ss.subscription.id ? planId : this.selectedPlan.id,
          payment_method_type: this.stripePaymentMethodService.paymentType,
        },
      })
        .then(async (response) => {
          if (response?.data?.client_secret) {
            return response.data.client_secret;
          }
          await store.dispatch('subscription/setActiveSubscription', response.data);
        })
        .catch((error: AxiosError) => {
          Sentry.captureException(error);
        });
    }
  }

  // No saving process going on and stripe form is ready?
  get isSubmitPossible(): boolean {
    return !this.isSaving;
  }

  // Sets the checkout-type (downgrade, upgrade, new)
  subscriptionCheckoutType(planId: string): void {
    const isUpgrade = this.stripeSubscriptionService.isPlanSuperior(planId);

    if (isUpgrade !== null) {
      this.checkoutType = isUpgrade ? 'upgrade' : 'downgrade';
    }
  }

  onTotalPriceChanged(price: string): void {
    if (price !== this.totalPrice) {
      this.totalPrice = price;
      this.priceHasBeenRecalculated = true;
    }
  }

  isWalletPaymentMethod(): boolean {
    return ['apple_pay', 'google_pay'].includes(this.stripePaymentMethodService.paymentType);
  }

  @Watch('stripeCheckoutService.legalEntity', { deep: true, immediate: false })
  async onLegalEntityChange(newVal: string): Promise<void> {
    if (newVal) {
      await this.handleVatRateUpdate();
    }
  }

  @Watch('stripeCheckoutService.customer.address', { deep: true, immediate: false })
  async onCityChange(newVal: string): Promise<void> {
    if (newVal) {
      await this.handleVatRateUpdate();
    }
  }

  async handleVatRateUpdate(): Promise<void> {
    this.isUpdating = true;
    await this.throttleUpdateVatRate();
    if (
      this.stripePaymentMethodService.paymentType !== 'google_pay' &&
      this.stripePaymentMethodService.paymentType !== 'apple_pay'
    ) {
      setTimeout(() => {
        this.isUpdating = false; // Set to false after 1 second to prevent fast flashing of loader
      }, 1000);
    }
  }

  checkAddressErrors(): void {
    const addressComponent = this.$refs.addressWithCombobox;
    const errorMessage = addressComponent ? addressComponent.getAddressFieldFirstError() : '';
    this.formErrorMessage = errorMessage || this.$t('fields.checkout.form.invalid').toString();
  }

  isUserOnFtcCheckboxAbTest(): boolean {
    return OptimizelyService.isUserOnFtcCheckboxAbTest(store.state?.auth?.user) ?? false;
  }
}
</script>

<style lang="scss">
#checkout {
  .checkout {
    // Container, which includes payment and summary
    &__container {
      border-radius: 12px;
      box-shadow: none;
      background: map-get($neutral, 'lighten-4');
    }

    // Container with all payment-fields
    &__payment {
      @media (max-width: 1263px) {
        order: 2;
      }

      @media (max-width: 600px) {
        padding: 12px 0;
      }
    }

    // Container with Summary and subscription-plan select
    &__summary {
      @media (max-width: 1263px) {
        order: 1;
      }

      &-container {
        border-radius: 12px;
        border: 2px solid map-get($neutral, 'lighten-2');
        padding: 16px 20px;
      }
    }

    // Header on top of page
    &__header {
      margin-bottom: 20px;

      &--light {
        font-weight: map-get($font-weights, 'regular');
        color: map-get($lapis, 'lighten-2');
      }
    }

    // Header of payment-step
    &__step {
      &-container {
        padding: 20px 40px 40px;

        @media (max-width: 600px) {
          padding: 14px 16px 16px;
        }

        .v-stepper__wrapper {
          overflow: visible;
        }
      }

      &-header {
        margin-bottom: 8px;
      }

      &-description {
        display: flex;
        justify-content: space-between;

        @media (max-width: 600px) {
          flex-direction: column;
          align-items: flex-start;
        }

        svg {
          margin-right: 6px;
        }
      }
    }

    // Container below the inputs
    &__payment-safety {
      background-color: map-get($navy, 'base');
      background-image: url('../../assets/images/checkout/lock-image.svg');
      background-repeat: no-repeat;
      background-position: -40px center;
      padding: 28px 40px 40px;
      text-align: center;

      &-header {
        color: map-get($neutral, 'lighten-4');
        margin-bottom: 8px;
        width: 100%;
      }

      &-text {
        color: map-get($primary, 'lighten-1');
        max-width: 416px;
        margin: 0;
      }
    }

    &__notes-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 8px;
    }

    &__notes-hint {
      font-size: 12px;
      font-style: italic;
      color: map-get($neutral, 'darken-4');
    }
  }

  // Radio buttons
  .radio-selection {
    &__type {
      height: 40px;
      border-radius: 4px;
      border: 1px solid rgba(map-get($neutral, 'darken-3'), 0.38);
      background: #ffffff;

      &:hover {
        border: 1px solid rgba(map-get($neutral, 'darken-3'), 0.86);
      }

      // Make space around radio-icon clickable by expanding the wrapper
      .v-input--selection-controls__input {
        margin: 0;
        height: 40px;
        width: 40px;

        input {
          min-height: 40px;
        }

        // adjust the ripple
        .v-input--selection-controls__ripple {
          left: 0;
          top: calc(50% - 20px);
        }
      }

      label {
        display: flex;
        justify-content: space-between;
        font-size: 18px;
        height: 100%;
        font-weight: map-get($font-weights, 'semi-bold');
        color: map-get($lapis, 'lighten-2');
        padding-right: 12px;
      }

      &.v-item--active {
        border: 1px solid map-get($navy, 'base');

        &:hover {
          box-shadow: 0 0 0 1px map-get($navy, 'base');
        }

        label {
          color: map-get($navy, 'base');
        }
      }
    }

    // radio-buttons for plan-selection
    &--plan {
      padding: 0;
      margin: 0 0 4px;

      .v-messages {
        display: none;
      }

      label {
        font-size: 16px;
        height: 100%;
        font-weight: map-get($font-weights, 'regular');
        color: map-get($lapis, 'lighten-2') !important;
      }

      .radio-selection {
        &__price {
          font-weight: map-get($font-weights, 'semi-bold');
          color: map-get($navy, 'base');
        }
      }
    }
  }

  .input_validation {
    font-size: 12px;
    padding-top: 38px;
    top: 8px;
    left: 0;
    color: map-get($begonia, 'base');
  }

  .input-validation-vat-id {
    margin-bottom: -26px;
  }

  .accept-tos-checkbox-label {
    a {
      color: map-get($lapis, 'lighten-2');
    }
  }
}

.cvv-modal__image {
  max-width: 360px;
  width: 100%;
  margin-bottom: 48px;
}

.sepa__mandate {
  font-size: 12px;
  line-height: 18px;
}

#errorAlert {
  .py-0.col.col-auto {
    width: 100%;
  }
}

.alert--fixed {
  position: sticky;
  top: 100px;
  z-index: 9999;
  width: 100%;
  background-color: white !important;
}

.alert-fix-button {
  color: map-get($navy, 'base');
  padding-right: 12px;

  &::after {
    content: '';
    position: relative;
    float: left;
    background: #00bfff;
    width: 100%;
    border-radius: 3px;
    top: 0;
    height: 2px;
  }

  &:hover,
  &:focus {
    color: map-get($navy, 'darken-1');
  }
}

.credit-card-wrapper {
  padding-top: 0 !important;
  padding-bottom: 24px !important;

  @media (max-width: 680px) {
    padding-bottom: 28px !important;
  }
}
</style>
