import 'reflect-metadata';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import Vue from 'vue';
import App from './App.vue';
import { i18n } from './i18n';
import './plugins/class-component-hooks';
import './plugins/sentry';
import './plugins/vee-validate';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
// TODO: Remove after full implementation of our own icon system
import Authenticator from '@/Services/Auth/Authenticator';
import { intercomBoot, intercomShutdown } from '@/plugins/intercom';
import { disableOptimizely } from '@/plugins/optimizely';
import '@/plugins/optimizely-feature-flags';
import snowplowInit from '@/plugins/snowplow';
import zendeskInit from '@/plugins/zendesk';
import '@mdi/font/css/materialdesignicons.css';
import dayjs from 'dayjs';

Vue.config.productionTip = false;

// Global components
const Vcard = () => import('@/components/Icons/Type/16/Outline/Vcard.vue');
const ErrorMessage = () => import('@/components/ErrorMessage.vue');
const ImageTile = () => import('@/components/ImageTile.vue');

Vue.component('Vcard', Vcard);
Vue.component('ErrorMessage', ErrorMessage);
Vue.component('ImageTile', ImageTile);

async function initialize() {
  initializeGTM();
  // set dataLayer variable to disable tracking scripts in GTM
  if (process.env.VUE_APP_GTM_LOAD_TRACKING === 'false') {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'disable_tracking',
      disable_tracking: true,
    });
    console.info('GTM loaded, tracking-scripts not running');
  }

  // Init zedensk before App renders
  zendeskInit();
  // Init snowplow before App renders
  snowplowInit();

  try {
    await Authenticator.init();
  } catch {
    return;
  }

  await Promise.all([
    store.dispatch('account/loadAccount'),
    store.dispatch('location/detectCountryByIp'),
    store.dispatch('subscription/init'),
  ]);

  // Since a store value (stripe_instance_country) is a dependency of the StripePaymentMethodService, we have to wait
  // for access to the store value to be initialized before instantiating StripePaymentMethodService. We initialize
  // it as an object in our vuex store here to have reactive access to it globally.
  const { stripe_instance_country } = store.state.auth.user;
  await store.commit('subscription/setStripePaymentMethodService', stripe_instance_country);
}

function initializeGTM() {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = '/js/gtm.js';
  document.head.appendChild(script);
}

function isNotE2EUser() {
  const userEmail = store.state.auth.user.email;
  if (userEmail.endsWith('@inbox.mailtrap.io')) {
    return false;
  }
  return true;
}

async function setLanguage() {
  const user = store.state.auth.user;
  const userLanguage = user?.language;
  const browserLanguage = navigator?.language?.substring(0, 2);

  const { fallbackLocale, availableLocales } = i18n;
  let languageToSelect = fallbackLocale;
  // Check if language is in our available languages and then apply translation
  if (userLanguage && availableLocales.includes(userLanguage)) {
    languageToSelect = userLanguage;
  } else if (browserLanguage && availableLocales.includes(browserLanguage)) {
    languageToSelect = browserLanguage;
  }

  dayjs.locale(languageToSelect.toString() || 'en');
  await store.dispatch('auth/setLang', languageToSelect);

  // After all checking set locale
  i18n.locale = languageToSelect.toString();
}

function createApp() {
  new Vue({
    router,
    store,
    i18n,
    vuetify,
    async beforeCreate() {
      await setLanguage();
      if (!isNotE2EUser()) {
        disableOptimizely();
      }
    },
    beforeMount(): void {
      document.getElementById('preloading')?.remove();
    },
    mounted(): void {
      intercomBoot();
    },
    beforeDestroy(): void {
      intercomShutdown();
    },
    render: (h) => h(App),
  }).$mount('#app');
}

initialize().finally(createApp);
