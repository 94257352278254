<script lang="ts">
// @ts-nocheck
import { Vue, Component, Prop } from 'vue-property-decorator';
import { VCheckbox } from 'vuetify/lib';
import Icon from '@/components/Icons/Icon.vue';

@Component({
  extends: VCheckbox,
})
export default class QrCheckbox extends Vue {
  @Prop({ type: Boolean })
  indeterminate?: boolean;

  @Prop()
  border?: boolean;

  @Prop()
  errorState?: boolean;

  @Prop({ type: String, default: 'check-indetermined-system' })
  indeterminateIcon?: string;

  @Prop({ type: String, default: 'check-unchecked-system' })
  offIcon?: string;

  @Prop({ type: String, default: 'check-checked-system' })
  onIcon?: string;

  get classes(): object {
    return {
      'qr-checkbox': true,
      'qr-checkbox--border': this.border,
      'qr-checkbox--error': this.errorState,
      'v-input--selection-controls': true,
      'v-input--checkbox': true,
      'v-input--indeterminate': this.inputIndeterminate,
    };
  }

  get computedIcon(): string {
    if (this.inputIndeterminate) {
      return this.indeterminateIcon;
    } else if (this.isActive) {
      return this.onIcon;
    } else {
      return this.offIcon;
    }
  }

  genCheckbox() {
    return this.$createElement(
      'div',
      {
        staticClass: 'v-input--selection-controls__input',
      },
      [
        this.$createElement(
          Icon,
          this.setTextColor(this.validationState, {
            props: {
              name: this.computedIcon,
              outline: !this.isActive,
            },
          })
        ),
        this.genInput('checkbox', {
          ...this.attrs$,
          'aria-checked': this.inputIndeterminate ? 'mixed' : this.isActive.toString(),
        }),
        this.$slots.default,
      ]
    );
  }
}
</script>

<style lang="scss">
.qr-checkbox {
  margin: 0;
  padding: 0;

  .v-label {
    color: map-get($lapis, 'lighten-2');
  }

  .v-input--selection-controls__input {
    align-items: center;
    justify-content: center;

    &:after {
      content: '';
      position: absolute;
      width: 24px;
      height: 24px;
      z-index: 9;
      left: 0;
      top: 0;
      border-radius: 4px;
      background-color: currentColor;
      opacity: 0;
    }

    &:hover {
      &:after {
        opacity: 0.2;
      }
    }

    // Workaround for the checkbox being cut off,
    // likely due to some rendering awkwardness in our checkout
    width: 25px;
    height: 25px;
    .v-icon {
      min-height: 18px;
      svg {
        min-height: 18px;
      }
    }

    > .icon {
      position: relative;
      z-index: 10;
    }

    input {
      z-index: 11;
      top: 0;
      right: 0;
    }
  }

  .v-input {
    &__control {
      flex-direction: unset;
      flex-wrap: unset;
    }

    &__slot {
      margin-bottom: 0;
    }
  }

  &--border {
    border: 1px solid rgba(map-get($neutral, 'darken-3'), 0.38);
    border-radius: 4px;
    margin-bottom: 8px;
    padding: 8px;

    &:hover {
      border-color: rgba(map-get($neutral, 'darken-3'), 0.86);
    }

    &.navy--text {
      border-color: map-get($navy, 'base');

      &:hover {
        box-shadow: 0 0 0 1px map-get($navy, 'base');
      }
    }
  }

  // Makes the checkbox border red when in error state
  &--error {
    .v-input--selection-controls__input {
      .v-icon {
        color: map-get($begonia, 'base');
      }
    }
  }
}
</style>
