import { trackStructEvent, trackPageView } from '@snowplow/browser-tracker';
import {
  SnowplowCancellationContext,
  SnowplowCancellationContextData,
  SnowplowEventContext,
  SnowplowFrontendContext,
  SnowplowUserContext,
  SnowplowAbTestContext,
} from './models';

export interface TrackingData {
  industry_id: number | null;
  language_code?: string;
  price_currency_displayed?: string;
  user_id: string;
  plan_id?: string;
}

export function trackCompletePurchaseButton(data: TrackingData): void {
  const eventToBeSent = {
    action: 'payment.complete_purchase_button.clicked',
    category: 'qr_codes.checkout',
    context: [
      new SnowplowEventContext({
        product: 'qrcg',
        call_to_action: 'payment.complete_purchase_button.clicked',
        event_intent: 'payment',
      }),
      new SnowplowFrontendContext({
        page_name: 'payment',
        element_type: 'button',
        source_location: 'payment_form',
        element_id: 'qr-payment-complete-purchase-button',
        element_name: 'complete_purchase',
        interaction_type: 'click',
        price_currency_displayed: data.price_currency_displayed,
        language_code: data.language_code,
      }),
      new SnowplowUserContext({
        industry_id: data.industry_id,
        tracking_consent_status: true,
        user_id: String(data.user_id),
      }),
    ],
  };
  trackStructEvent(eventToBeSent);
}

export function trackCheckoutNowButtonPricingPage(data: TrackingData): void {
  const eventToBeSent = {
    action: 'subscribe.checkout_now_button.clicked',
    category: 'qr_codes.pricing',
    context: [
      new SnowplowEventContext({
        product: 'qrcg',
        call_to_action: 'CHECKOUT NOW',
        event_intent: 'payment',
      }),
      new SnowplowFrontendContext({
        page_name: 'pricing',
        element_type: 'button',
        source_location: 'pricing_page',
        element_id: data.plan_id,
        element_name: 'checkout_now',
        interaction_type: 'click',
        price_currency_displayed: data.price_currency_displayed,
        language_code: data.language_code,
      }),
      new SnowplowUserContext({
        industry_id: data.industry_id,
        tracking_consent_status: true,
        user_id: String(data.user_id),
      }),
    ],
  };
  trackStructEvent(eventToBeSent);
}

export function trackCancelSubscription(data: TrackingData & SnowplowCancellationContextData): void {
  const eventToBeSent = {
    action: 'cancel_subscription.cancel_subscription_button.clicked',
    category: 'qr_codes.cancellation',
    context: [
      new SnowplowEventContext({
        product: 'qrcg',
        call_to_action: 'Cancel Subscription',
        event_intent: 'navigate_to_final_cancellation_step',
      }),
      new SnowplowFrontendContext({
        page_name: 'cancel_subscription',
        element_type: 'button',
        element_name: 'cancel_subscription_button',
        interaction_type: 'click',
      }),
      new SnowplowUserContext({
        industry_id: data.industry_id,
        tracking_consent_status: true,
        user_id: String(data.user_id),
      }),
      new SnowplowCancellationContext({
        reason_identifier: data.reason_identifier || null,
        reason_text: data.reason_text || null,
        frontend_validation_succeeded: data.frontend_validation_succeeded,
        ab_test: data.ab_test || null,
      }),
    ],
  };
  trackStructEvent(eventToBeSent);
}

export function trackRejectDiscountCloseButtonClicked(data: TrackingData): void {
  const eventToBeSent = {
    action: 'reject_discount.close_button.clicked',
    category: 'qr_codes.cancellation',
    context: [
      new SnowplowEventContext({
        product: 'qrcg',
        call_to_action: 'X',
        event_intent: 'billing_page_viewed',
      }),
      new SnowplowFrontendContext({
        element_type: 'button',
        interaction_type: 'click',
        page_name: 'cancellation form',
        source_location: 'cancellation form',
      }),
      new SnowplowUserContext({
        industry_id: data.industry_id,
        tracking_consent_status: true,
        user_id: String(data.user_id),
      }),
    ],
  };
  trackStructEvent(eventToBeSent);
}

export function trackAcceptDiscountAcceptThisOfferButtonClicked(data: TrackingData): void {
  const eventToBeSent = {
    action: 'accept_discount.accept_this_offer_button.clicked',
    category: 'qr_codes.cancellation',
    context: [
      new SnowplowEventContext({
        product: 'qrcg',
        call_to_action: 'ACCEPT THIS OFFER',
        event_intent: 'thank_you_page_viewed',
      }),
      new SnowplowFrontendContext({
        element_type: 'button',
        interaction_type: 'click',
        page_name: 'cancellation form',
        source_location: 'cancellation form',
      }),
      new SnowplowUserContext({
        industry_id: data.industry_id,
        tracking_consent_status: true,
        user_id: String(data.user_id),
      }),
    ],
  };
  trackStructEvent(eventToBeSent);
}

export function trackRejectDiscountNotThanksButtonClicked(data: TrackingData): void {
  const eventToBeSent = {
    action: 'reject_discount.not_thanks_button.clicked',
    category: 'qr_codes.cancellation',
    context: [
      new SnowplowEventContext({
        product: 'qrcg',
        call_to_action: 'No Thanks, Cancel My Subscription',
        event_intent: 'billing_page_viewed',
      }),
      new SnowplowFrontendContext({
        element_type: 'link',
        interaction_type: 'click',
        page_name: 'cancellation form',
        source_location: 'cancellation form',
      }),
      new SnowplowUserContext({
        industry_id: data.industry_id,
        tracking_consent_status: true,
        user_id: String(data.user_id),
      }),
    ],
  };
  trackStructEvent(eventToBeSent);
}

export interface PageViewTrackingData {
  page_name: string;
  user_id: string | number;
  industry_id: number | null;
  language_code?: string;
  price_currency_displayed?: string;
  event_type: string | any;
  group: string | any;
  name: string | any;
}

export function trackSnowplowPageView(data: PageViewTrackingData): void {
  const eventToBeSent = {
    context: [
      new SnowplowFrontendContext({
        page_name: data.page_name,
        interaction_type: 'navigation',
        language_code: data.language_code,
      }),
      new SnowplowUserContext({
        tracking_consent_status: true,
        industry_id: data.industry_id,
        user_id: String(data.user_id),
      }),
      new SnowplowAbTestContext({
        event_type: data.event_type.toString(),
        group: data.group.toString(),
        name: data.name.toString(),
      }),
    ],
  };
  trackPageView(eventToBeSent);
}

export function trackUndoCancellationKeepSubscriptionButtonClicked(data: TrackingData): void {
  const eventToBeSent = {
    action: 'undo_cancellation.keep_subscription_button.clicked',
    category: 'qr_codes.billing',
    context: [
      new SnowplowUserContext({
        industry_id: data.industry_id,
        tracking_consent_status: true,
        user_id: String(data.user_id),
      }),
    ],
  };
  trackStructEvent(eventToBeSent);
}

export function trackCancelSubscriptionKeepSubscriptionButtonClicked(data: TrackingData): void {
  const eventToBeSent = {
    action: 'cancel_subscription.keep_subscription_button.clicked',
    category: 'qr_codes.cancellation',
    context: [
      new SnowplowUserContext({
        industry_id: data.industry_id,
        tracking_consent_status: true,
        user_id: String(data.user_id),
      }),
    ],
  };
  trackStructEvent(eventToBeSent);
}

export function trackManageSubscriptionUpgradePlanButtonClicked(data: TrackingData): void {
  const eventToBeSent = {
    action: 'manage_subscription.upgrade_plan_button.clicked',
    category: 'qr_codes.subscription_management',
    context: [
      new SnowplowUserContext({
        industry_id: data.industry_id,
        tracking_consent_status: true,
        user_id: String(data.user_id),
      }),
    ],
  };
  trackStructEvent(eventToBeSent);
}

export function trackManageSubscriptionCancelPlanButtonClicked(data: TrackingData): void {
  const eventToBeSent = {
    action: 'manage_subscription.cancel_plan_button.clicked',
    category: 'qr_codes.subscription_management',
    context: [
      new SnowplowUserContext({
        industry_id: data.industry_id,
        tracking_consent_status: true,
        user_id: String(data.user_id),
      }),
    ],
  };
  trackStructEvent(eventToBeSent);
}

export function trackCancelSubscriptionModalKeepBenefitsButtonClicked(data: TrackingData): void {
  const eventToBeSent = {
    action: 'cancel_subscription_modal.keep_benefits_button.clicked',
    category: 'qr_codes.cancellation',
    context: [
      new SnowplowUserContext({
        industry_id: data.industry_id,
        tracking_consent_status: true,
        user_id: String(data.user_id),
      }),
    ],
  };
  trackStructEvent(eventToBeSent);
}

export function trackCancelSubscriptionModalContactUsButtonClicked(data: TrackingData): void {
  const eventToBeSent = {
    action: 'cancel_subscription_modal.contact_us_button.clicked',
    category: 'qr_codes.cancellation',
    context: [
      new SnowplowUserContext({
        industry_id: data.industry_id,
        tracking_consent_status: true,
        user_id: String(data.user_id),
      }),
    ],
  };
  trackStructEvent(eventToBeSent);
}

export function trackCancelSubscriptionModalWantToCancelButtonClicked(data: TrackingData): void {
  const eventToBeSent = {
    action: 'cancel_subscription_modal.want_to_cancel_button.clicked',
    category: 'qr_codes.cancellation',
    context: [
      new SnowplowUserContext({
        industry_id: data.industry_id,
        tracking_consent_status: true,
        user_id: String(data.user_id),
      }),
    ],
  };
  trackStructEvent(eventToBeSent);
}

export function trackCancelSubscriptionModalCloseButtonClicked(data: TrackingData): void {
  const eventToBeSent = {
    action: 'cancel_subscription_modal.close_button.clicked',
    category: 'qr_codes.cancellation',
    context: [
      new SnowplowUserContext({
        industry_id: data.industry_id,
        tracking_consent_status: true,
        user_id: String(data.user_id),
      }),
    ],
  };
  trackStructEvent(eventToBeSent);
}
